<script setup lang="ts">
import {
  Scene,
  WebGLRenderer,
  Color,
  AmbientLight,
  Vector2,
  DirectionalLight,
  LoadingManager,
  OrthographicCamera,
  Box3,
  Vector3
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { LuminosityShader } from 'three/examples/jsm/shaders/LuminosityShader.js'

import { unzip } from 'unzipit'

const props = withDefaults(
  defineProps<{
    modelZipUrl: string;
    horizontal?: number;
    vertical?: number;
    zoom?: number;
    ambient?: string;
    directional?: string;
    controls?: boolean;
    rotateX?: boolean;
    rotateY?: boolean;
    rotateZ?: boolean;
  }>(),
  {
    horizontal: 0,
    vertical: 0.1,
    zoom: 1.5,
    ambient: '#3700B3',
    directional: '#B899FF',
    controls: false,
    rotateX: true,
    rotateY: true,
    rotateZ: true
  }
)

let renderer: WebGLRenderer
let composer: EffectComposer
let renderPass: RenderPass
let sceneControls: OrbitControls

const conSize: Ref<HTMLElement | null> = ref(null)
const experience: Ref<HTMLCanvasElement | null> = ref(null)

const width = ref(1)
const height = ref(1)
const aspectRatio = computed(() => width.value / height.value)

const ambient = new Color(props.ambient)
const directional = new Color(props.directional)

const scene = new Scene()

function countDecimals (num: number) {
  if (Math.floor(num.valueOf()) === num.valueOf()) { return 0 }
  const numStr = num.toString().split('.')[1]
  const places = (numStr.match(/^0+/) || [''])[0].length
  return places > 0 ? places + 2 : places
}

function normalizeValues (num: number, baseNum: number) {
  const decimalPlace = countDecimals(baseNum)
  const baseVal = parseInt('1'.padEnd(decimalPlace, '0'))
  return (num / baseVal)
}

const fitCameraToCenteredObject = (
  camera: OrthographicCamera,
  object: any,
  horizontal?: number,
  vertical?: number,
  zoom?: number
) => {
  const boundingBox = new Box3()
  boundingBox.setFromObject(object)
  const size = boundingBox.getSize(new Vector3())
  const maxSize = Math.max(size.x, size.y, size.z)
  const newPositionCamera = new Vector3(maxSize, maxSize, maxSize)
  camera.zoom = zoom ?? 1
  camera.left = -(2 * maxSize)
  camera.bottom = -(2 * maxSize)
  camera.top = 2 * maxSize
  camera.right = 2 * maxSize
  camera.near = -maxSize * 4
  camera.far = maxSize * 4
  // camera
  const newPositionCameraX = typeof horizontal === 'number' ? newPositionCamera.x + normalizeValues(horizontal, newPositionCamera.x) : newPositionCamera.x
  const newPositionCameraY = typeof vertical === 'number' ? newPositionCamera.y + normalizeValues(vertical, newPositionCamera.y) : newPositionCamera.y

  camera.position.set(
    newPositionCameraX,
    newPositionCameraY,
    newPositionCamera.z
  )
  camera.lookAt(0, 0, 0)
  camera.updateProjectionMatrix()
}

const camera = new OrthographicCamera(-1, 1, 1, -1, 0.1, 2000)
scene.add(camera)

const ambientLight = new AmbientLight(ambient, 7)
scene.add(ambientLight)

const directionalLight = new DirectionalLight(directional, 10)
directionalLight.position.set(3, 3, 3)
scene.add(directionalLight)

async function readFiles (url: string) {
  type MineTypes = 'gltf' | 'bin' | 'png'
  const { entries } = await unzip(url)
  const blobs: {[key: string]: any} = {}
  const mimeTypes = {
    gltf: 'model/gltf+json',
    bin: 'application/octet-stream',
    png: 'image/png'
  }
  // print all entries and their sizes
  for (const [name, entry] of Object.entries(entries)) {
    if (['.gltf', '.bin', '.png'].some(fileType => name.includes(fileType) && !name.includes('__MACOSX'))) {
      const extension = name.split('.')[1] as MineTypes
      const mimeType = mimeTypes[extension]

      blobs[`./${name}`] = await entry.blob(mimeType)
    }
  }
  return blobs
}

const blobs = await readFiles(props.modelZipUrl)

const manager = new LoadingManager()
// Initialize loading manager with URL callback.
const objectURLs: string[] = []
manager.setURLModifier((url) => {
  url = URL.createObjectURL(blobs[url])
  objectURLs.push(url)
  return url
})

const { load } = useGLTFModel(manager)

try {
  const { scene: model } = await load('./scene.gltf') as { scene: any }
  scene.add(model)

  objectURLs.forEach(url => URL.revokeObjectURL(url))
  const { horizontal, vertical, zoom } = props
  fitCameraToCenteredObject(camera, model, horizontal, vertical, zoom)
} catch (error) {
  const { devMode } = useRuntimeConfig().public
  if (devMode) {
    // eslint-disable-next-line no-console
    console.error('3D model could not be loaded\n', error)
  }
}

function updateCamera () {
  camera.updateProjectionMatrix()
}

function updateRenderer () {
  renderer.setSize(width.value, height.value)
  renderer.render(scene, camera)
  const bloomPass = new UnrealBloomPass(new Vector2(width.value, height.value), 10, 5, 10)
  const luminancePass = new ShaderPass(LuminosityShader)
  composer.addPass(renderPass)
  composer.addPass(bloomPass)
  composer.addPass(luminancePass)
}

function setRenderer () {
  if (experience.value) {
    renderer = new WebGLRenderer({ canvas: experience.value, alpha: true, antialias: true })
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
    if (props.controls) {
      sceneControls = new OrbitControls(camera, renderer.domElement)
      sceneControls.enableDamping = true
    }
    composer = new EffectComposer(renderer)
    renderPass = new RenderPass(scene, camera)

    updateRenderer()
  }
}

function outputsize () {
  if (conSize.value) {
    width.value = conSize.value.offsetWidth
    height.value = conSize.value.offsetWidth
    updateCamera()
    updateRenderer()
  }
}
watch(aspectRatio, () => {
  updateCamera()
  updateRenderer()
})

watch(experience, () => {
  setRenderer()
  loop()
})
watch(conSize, () => {
  if (conSize.value) {
    new ResizeObserver(outputsize).observe(conSize.value)
    outputsize()
  }
})
const loop = () => {
  if (!scene?.children?.[3]) { return }
  if (props.controls) { sceneControls.update() }
  renderer.render(scene, camera)
  requestAnimationFrame(loop)
  if (props.rotateX) {
    scene.children[3].children[0].rotation.x += 0.005
  }
  if (props.rotateY) {
    scene.children[3].children[0].rotation.y += 0.005
  }
  if (props.rotateZ) {
    scene.children[3].children[0].rotation.z += 0.005
  }
}
</script>
<template>
  <div ref="conSize" class="flex size-full justify-center">
    <canvas ref="experience" />
  </div>
</template>
