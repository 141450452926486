<template>
  <div
    ref="hero"
    :class="[
      `hero text-main relative flex size-full items-center bg-cover`,
      themeStyles(theme),
      theme,
      { 'py-2': spacing === 'sm' },
      { 'py-4': spacing === 'md' },
      { 'py-6': spacing === 'lg' },
      { 'py-8': spacing === 'xl' },
      { 'lg:h-screen': fullScreen },
      { 'min-h-[40vh]': bgImage && !fullScreen || bgVideo && !fullScreen },
    ]"
    :style="bgStyles"
  >
    <img v-if="bgImage && fullScreen" :src="bgImage" class="absolute size-full object-cover" alt="hero img">
    <video
      v-else-if="bgVideo"
      :data-fullscreen="fullScreen"
      :class="['absolute size-full object-cover']"
      :controls="false"
      autoplay="true"
      loop="true"
      muted="true"
      name="media"
    >
      <source :src="bgVideo" type="video/mp4">
    </video>
    <div
      :class="[
        'inner-wrapper max-wrapper relative flex size-full flex-wrap items-center px-6',
        { 'readable': readable },
        { 'py-12 md:py-16 lg:py-20 xl:py-20': bgImage || bgVideo },
        { 'justify-around': heroImage?.asset || threeDModel },
        { 'justify-start': !heroImage?.asset && !threeDModel }
      ]"
    >
      <div
        :class="[
          'text-overlay',
          { 'md:w-6/12': !hasTextOnly },
          { 'md:w-full': hasTextOnly && !maxWidth },
          { 'lg:w-6/12': maxWidth === '6/12' },
          { 'lg:w-7/12': maxWidth === '7/12' },
          { 'lg:w-8/12': maxWidth === '8/12' },
          { 'lg:w-9/12': maxWidth === '9/12' },
          { 'lg:w-10/12': maxWidth === '10/12' },
          { 'lg:w-11/12': maxWidth === '11/12' },
          { 'lg:w-full': maxWidth === 'full' },
        ]"
      >
        <p v-if="eyebrow" class="font-HeadlineBold !text-sm uppercase tracking-[2px] opacity-80">
          {{ eyebrow }}
        </p>
        <BlockContent v-if="mainContent" :content="mainContent" />
        <div v-if="ctas?.length" class="ctas flex flex-col flex-wrap">
          <POButton
            v-for="(cta, index) in ctas"
            :id="cta.gaTag"
            :key="index"
            :class="['mr-4 mt-6', theme]"
            :color="cta.color"
            :link="cta[cta.linkType]"
          >
            {{ cta.text }}
          </POButton>
        </div>
      </div>
      <div v-if="heroImage" class="max-w-90 lg:max-w-30 w-full pt-8 sm:max-w-60 sm:pt-8 md:pt-8 lg:px-3 lg:pt-0">
        <img v-if="heroImage.asset" class="w-full" :src="heroImage.asset" :alt="heroImage.alt">
      </div>
      <ClientOnly>
        <div v-if="threeDModel?.zipFile && !heroImage?.asset" class="md:max-w-4/12 max-w-7/12 flex w-full justify-center pt-8 sm:pt-8 md:pt-8 lg:px-3 lg:pt-0">
          <OpalThree
            :model-zip-url="threeDModel?.zipFile"
            :horizontal="threeDModel?.posision?.horizontal"
            :vertical="threeDModel?.posision?.vertical"
            :zoom="threeDModel?.posision?.zoom"
            :ambient="threeDModel.colors?.ambientLight?.hex"
            :directional="threeDModel.colors?.directionalLight?.hex"
            :controls="threeDModel?.controls"
            :rotate-x="threeDModel?.rotate?.rotateX"
            :rotate-y="threeDModel?.rotate?.rotateY"
            :rotate-z="threeDModel?.rotate?.rotateZ"
          />
        </div>
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">
interface Props {
  eyebrow?: string;
  readable?: boolean;
  template: 'full' | 'normal';
  spacing?: 'sm'|'md'|'lg'|'xl';
  maxWidth?: '6/12' | '7/12' | '8/12' | '9/12' | '10/12' | '11/12' | 'full';
  mainContent?: BlockContent;
  ctas?: CTA[];
  theme: Theme;
  bgImage?: string;
  bgVideo?: string;
  heroImage?: {
    asset: string,
    alt: string;
  };
  threeDModel?: {
    zipFile: string;
    posision: {
      horizontal: number;
      vertical: number;
      zoom: number;
    };
    colors: {
      ambientLight: any;
      directionalLight: any;
    };
    controls?: boolean;
    rotate: {
      rotateX?: boolean;
      rotateY?: boolean;
      rotateZ?: boolean;
    }
  };
}
const props = defineProps<Props>()
const hero = ref<HTMLElement>()
const fullScreen = computed(() => props.template === 'full')
const hasTextOnly = computed(() => (!props.heroImage && !props.threeDModel))
const bgStyles = computed(() => props.bgImage ? { 'background-image': `url(${props.bgImage})` } : '')

const emit = defineEmits(['mounted', 'updated'])
onMounted(() => emit('mounted'))
onUpdated(() => emit('updated'))
</script>
<style lang="scss" scoped>
.max-wrapper {
  @apply pt-24 lg:pt-6 pb-12 p-6 lg:p-6;
}
.light {
  .text-overlay {
    @apply text-black
  }
}
</style>
